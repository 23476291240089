<template>
  <div>
    <label v-show="customLabel" class="pb-0 mb-0">{{ customLabel }}</label>
    <div>
      <v-text-field
        v-bind="$attrs"
        @input="onInput"
        :class="['custom-text-field', cssClasses]"
        outlined
        append-icon="mdi-eye"
        :type="chooseType()"
      >
        <template  v-slot:append>
          <v-icon v-show="customType==='password'" @click="onShowPassword">{{
            showPassword ? 'mdi-eye-off' : 'mdi-eye'
          }}</v-icon>
      </template>
      </v-text-field>
</div>
  </div>
</template>

<script>
export default {
  name: 'CustomTextField',
  data () {
    return {
      showPassword: false
    };
  },
  props: {
    cssClasses: {
      type: String,
      default: ''
    },
    customType: {
      type: String,
      default: 'text'
    },
    customLabel: {
      type: String,
      default: null
    }
  },
  methods: {
    onInput (value) {
      this.internalValue = value;
      this.$emit('update:modelValue', value);
      this.$emit('input', value);
    },
    onShowPassword () {
      this.showPassword = !this.showPassword;
    },
    chooseType () {
      if (this.customType === 'password') {
        return this.showPassword ? 'text' : 'password';
      }
      return this.customType;
    }
  }
};
</script>
