<template>
  <div :class="($vuetify.breakpoint.smAndDown) ? 'sm' : ''">
    <h3 v-if="hasTitle" class="title secondary--text">
      <slot name="title" ></slot>
    </h3>
    <v-card v-if="hasContent" elevation="0" class="details-card">
      <v-card-text>
        <slot name="content" ></slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'DetailsCard',
  computed: {
    hasTitle () {
      return !!this.$slots.title;
    },
    hasContent () {
      return !!this.$slots.content;
    }
  }
};
</script>
<style scoped>
  h3.title{
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 24px;
  }

  .details-card {
    border-radius: 32px;
    margin-bottom: 40px;
    box-shadow: 0px 3px 50px 0px #3F44A612 !important;
  }

  .details-card >>> .v-card__text {
    padding: 40px;
  }
</style>
<style lang="scss" scoped>
  .sm {
    .details-card .v-card__text {
      padding: 24px 32px;
    }
  }
</style>
