<template>
  <!-- <component :is="svgPath" /> -->
  <img :src="svgSrc" :alt="icon" />
</template>

<script>
// @TODO: see a way to import all the icons from the svg folder

  // import edit from './svg/edit.svg';
  // import direcciones from './svg/direcciones.svg';
  // import mail from './svg/mail.svg';
  // import mailFilled from './svg/mail-filled.svg';
  // import trabajos from './svg/trabajos.svg';
  // import user from './svg/user.svg';
  // import whatsapp from './svg/whatsapp.svg';

  // const icons = {
  //   edit,
  //   direcciones,
  //   mail,
  //   'mail-filled': mailFilled,
  //   trabajos,
  //   user,
  //   whatsapp
  // };

  export default {
    props: {
      icon: {
        type: String,
        required: true
      }
    },
    computed: {
      svgSrc () {
        return require(`./svg/${this.icon}.svg`);
      }
      // svgPath () {
      //   return icons[this.icon]
      // }
    }
  }
</script>
