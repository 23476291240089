<template>
<v-row>
  <v-col cols="12" md="12">
  <div>
    <v-sheet
      tile
      class="d-flex justify-content-between mt-5 border-bottom border-primary"
    >
      <v-btn
        icon
        class="ma-2"
        @click="$refs.calendar.prev()"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <h3 v-if="$refs.calendar" class="text-center py-3">{{ $refs.calendar.title }}</h3>

      <v-btn
        icon
        class="ma-2"
        @click="$refs.calendar.next()"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>

    </v-sheet>

    <v-sheet>
      <div v-if="!isSearchedUser" class="d-flex justify-content-around col-md-8 col-12 mx-auto py-4">
        <user-calendar-availability @close-availability-dialog="closeAvailabilityDialog"/>
        <user-calendar-special-dates @close-availability-dialog="closeAvailabilityDialog"/>
      </div>

      <user-calendar-event
        :dialog="eventDialog"
        :selected-date="selectedDate"
        :selected-event="selectedEvent"
        :profile-user-id="profileUserId"
        :my-calendar="isMyCalendar"
        @close-event-dialog="closeEventDialog"
        @changed-event-date="changedEventDate"
      />

      <div>
        <div class="row">
          <div class="mb-6 col-12 text-center p-0">
            <p class="text-overline mt-0 mb-2">Referencias</p>
            <v-badge
              color="bg-green-dark-prosk"
              :content="$t('accepted')"
              inline
            ></v-badge>
            <v-badge
              color="blue"
              :content="$t('pendingApproval')"
              inline
            ></v-badge>
            <v-badge
              color="bg-green-prosk"
              :content="$t('available')"
              inline
              ></v-badge>
              <v-badge
              color="#ccc"
              :content="$t('notAvailable')"
              inline
              ></v-badge>
          </div>
        </div>
        <v-calendar
          ref="calendar"
          v-model="value"
          :weekdays="weekday"
          :type="type"
          :events="events"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          :locale="$i18n.locale"
          color="#59d79d"
          @change="getEvents"
          @click:event="showEvent"
          @click:date="scheduleEvent"
          @click:day="scheduleEvent"
        >
          <template v-slot:day-label="{day, date}">
            <div :class="{ 'text-center': true, 'available-slot': isAvailable(date), 'non-available-slot': !isAvailable(date) }" @click="scheduleEvent({ date: date })">
              {{day}}
            </div>
          </template>
        </v-calendar>
      </div>
    </v-sheet>
  </div>
  </v-col>
</v-row>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import CryptoJS from 'crypto-js';

import { AES_SECRET } from '@/misc/constants';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import UserCalendarAvailability from '@/views/User/UserCalendarAvailability';
import UserCalendarEvent from '@/views/User/UserCalendarEvent';
import UserCalendarSpecialDates from '@/views/User/UserCalendarSpecialDates';
import userDataMixin from '@/mixins/userDataMixin';

export default {
  name: 'UserCalendar',
  components: {
    UserCalendarAvailability,
    UserCalendarSpecialDates,
    UserCalendarEvent
  },
  props: ['isSearchedUser'],
  mixins: [errorDialog, userDataMixin],
  data () {
    return {
      type: 'month',
      types: [
        { value: 'month', text: this.$t('month') },
        { value: 'week', text: this.$t('week') },
        { value: 'day', text: this.$t('day') }
      ],
      calendarStartDate: '',
      calendarEndDate: '',
      mode: 'stack',
      weekday: [1, 2, 3, 4, 5, 6, 0], // Mon to Sun
      selectedDate: '',
      selectedEvent: '',
      eventDialog: false,
      schedules: [],
      specialDates: [],
      loading: true,
      profileUserId: null,
      selectingEvent: false,
      value: '',
      events: [],
      isMyCalendar: false
    };
  },
  computed: {
    currentUser () {
      return this.$store.getters['auth/currentUser'];
    }
  },
  created () {
    this.setImportedConstants();
    const userUrlData = this.$route.params.query.split('-');
    if (userUrlData.length < 2) {
      // throw error
    } else {
      const hashedUserId = userUrlData[1];
      const bytes = CryptoJS.AES.decrypt(hashedUserId, this.$options.AES_SECRET);
      this.profileUserId = bytes.toString(CryptoJS.enc.Utf8);
      this.isMyCalendar = parseInt(this.profileUserId) === parseInt(this.currentUser.id);
      this.getProskerSchedules(this.profileUserId);
    }
  },
  methods: {
      setImportedConstants () {
        this.$options.AES_SECRET = AES_SECRET;
      },
      closeEventDialog (closeEvent) {
        if (closeEvent.reloadEvents === true) {
          this.getEvents({ start: this.calendarStartDate, end: this.calendarEndDate });
        }
        this.selectingEvent = false;
        this.eventDialog = false;
      },
      closeAvailabilityDialog (closeEvent) {
        if (closeEvent.reloadEvents === true) {
          this.getProskerSchedules(this.profileUserId);
        }
      },
      getEventName (event) {
        if (this.isMyCalendar) {
          const clientName = this.currentUser.id === event.client.id ? `${event.prosker.first_name} ${event.prosker.last_name}` : `${event.client.first_name} ${event.client.last_name}`;
          return (event.job_id) ? event.job.title : (this.currentUser.id === event.client.id && this.currentUser.id === event.prosker.id) ? this.$t('myEvent') : this.$t('eventWith', { clientName: clientName });
        }

        return (event.prosker_id === this.currentUser.id || event.client_id === this.currentUser.id) ? this.$t('myEvent') : this.$t('someEvent');
      },
      getEvents ({ start, end }) {
        this.calendarStartDate = start;
        this.calendarEndDate = end;
        this.loading = true;
        const proskerId = `,prosker_id=${this.profileUserId}|`;
        const clientId = `,client_id=${this.profileUserId}|`;
        const proskerUrl = `${process.env.VUE_APP_BASE_URL}/api/events?filters=event_date>=${start.date},event_date<=${end.date}${proskerId}`;
        const clientUrl = `${process.env.VUE_APP_BASE_URL}/api/events?filters=event_date>=${start.date},event_date<=${end.date}${clientId}`;
        axios
        .all([axios.get(proskerUrl), axios.get(clientUrl)])
        .then(axios.spread((response1, response2) => {
            const proskerEvents = response1.data.data;
            const clientEvents = response2.data.data;

            // Combine and process events from both responses
            const combinedEvents = [...proskerEvents, ...clientEvents].map(event => {
                event.name = this.getEventName(event);
                event.start = new Date(`${event.event_date}T${event.start_time}`);
                event.end = new Date(`${event.event_date}T${event.end_time}`);
                event.color = (!event.approved) ? 'blue' : (event.approved === -1) ? 'display-none' : 'bg-green-dark-prosk';
                event.timed = true;
                return event;
            });

            this.events = combinedEvents;
            this.loading = false;
          }))
          .catch((error) => {
              this.loading = false;
              this.showError(error);
          });
      },
      getEventColor (event) {
        return event.color;
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a;
      },
      showEvent (eventData) {
        const event = eventData.event;
        this.selectedDate = event.event_date;
        this.selectingEvent = true;
        const proskerId = (this.isMyCalendar) ? this.currentUser.id : this.profileUserId;
        if (!this.isMyCalendar && event.client_id !== this.currentUser.id && event.prosker.id !== this.currentUser.id) {
          this.showError({ message: this.$t('cannotReadEvent') });
        } else {
          this.getProskerAvailability(proskerId, this.selectedDate)
          .then((response) => {
              this.selectedEvent = {
                id: event.id,
                is_new: false,
                event_date: event.event_date,
                prosker_id: event.prosker_id,
                client_id: event.client_id,
                job_id: event.job_id,
                comments: event.comments,
                start_time: event.start_time.slice(0, -3),
                end_time: event.end_time.slice(0, -3),
                approved: event.approved.toString(),
                available_times: this.parseAvailableTimes(response),
                client: event.client,
                prosker: event.prosker
              };
              this.eventDialog = true;
          })
          .catch((error) => {
              this.loading = false;
              this.showError(error);
          });
        }
      },
      validDate (event) {
        return moment(event.date).isSameOrAfter(moment().toDate(), 'day');
      },
      changedEventDate (dateEvent) {
        this.selectedEvent.date = dateEvent.date;
        this.selectedEvent.event_date = dateEvent.date;
        this.scheduleEvent(this.selectedEvent);
      },
      parseAvailableTimes (response) {
        return response.data.data.sort((a, b) => {
          if (a.start_time < b.start_time) {
            return -1;
          }
          if (a.start_time > b.start_time) {
            return 1;
          }
          return 0;
        }).map(item => {
          item.start_time = item.start_time.slice(0, -3);
          item.end_time = item.end_time.slice(0, -3);
          return item;
        });
      },
      scheduleEvent (event) {
        if (!this.selectingEvent) {
          if (!this.isMyCalendar) {
            if (!this.validDate(event)) {
              this.eventDialog = false;
              // this.showError({ message: this.$t('cannotScheduleOldDates') });
            } else {
              this.selectedDate = event.date;
              this.getProskerAvailability(this.profileUserId, this.selectedDate)
              .then((response) => {
                  this.selectedEvent = {
                    is_new: true,
                    event_date: event.date,
                    prosker_id: this.profileUserId,
                    client_id: this.currentUser.id,
                    job_id: null,
                    comments: '',
                    start_time: '',
                    end_time: '',
                    approved: '0',
                    available_times: this.parseAvailableTimes(response)
                  };
                  this.eventDialog = true;
              })
              .catch(() => {
                  this.loading = false;
                  // this.showError(error);
              });
            }
          }
        }
      },
      getProskerAvailability (userId, eventDate) {
          this.loading = true;
          const url = `${process.env.VUE_APP_BASE_URL}/api/prosker-availability?date=${eventDate}&prosker_id=${userId}`;
          return axios.get(url);
      },
      getProskerSchedules (userId) {
        const schedulesUrl = `${process.env.VUE_APP_BASE_URL}/api/prosker-schedules?filters=prosker_id=${userId}|`;
        const specialDatesUrl = `${process.env.VUE_APP_BASE_URL}/api/prosker-special-dates?filters=prosker_id=${userId}|`;
        axios
        .all([axios.get(schedulesUrl), axios.get(specialDatesUrl)])
        .then(axios.spread((response1, response2) => {
          this.schedules = response1.data.data;
          this.specialDates = response2.data.data;
        }))
        .catch((error) => {
          console.log(error);
        });
      },
      isAvailable (date) {
        const daysMap = {
          1: 'monday',
          2: 'tuesday',
          3: 'wednesday',
          4: 'thursday',
          5: 'friday',
          6: 'saturday',
          7: 'sunday'
        };
        const momentDate = moment(date);
        return (
          this.schedules
          .filter(schedule => !schedule.closed)
          .map(schedule => schedule.day)
          .includes(daysMap[momentDate.day()]) ||
          this.specialDates
          .filter(item => !item.closed)
          .map(item => item.special_date)
          .includes(momentDate.format('YYYY-MM-DD'))
        ) &&
          !this.specialDates
          .filter(item => item.closed)
          .map(item => item.special_date)
          .includes(momentDate.format('YYYY-MM-DD'));
      },
      sendDataLayerInfo () {
      this.trackUserData('load_content', {
      pageType: 'my_account',
      pageSubType: 'schedule'
    });
    }
    }
};
</script>
