import {
    JOB_STATUS_CLIENT_CANCEL, JOB_STATUS_CLIENT_HELP_REQUEST, JOB_STATUS_CLIENT_REJECTED, JOB_STATUS_COMPLETED,
    JOB_STATUS_EXPIRED,
    JOB_STATUS_IN_PROGRESS,
    JOB_STATUS_NEW, JOB_STATUS_PROSKER_CANCEL, JOB_STATUS_PROSKER_COMPLETE, JOB_STATUS_PROSKER_HELP_REQUEST,
    JOB_STATUS_READY_TO_EXECUTE,
    JOB_STATUS_REJECTED
} from '@/misc/constants';

const mixin = {
    data () {
        return {
        };
    },
    created () {
        this.setImportedConstants();
    },
    methods: {
        setImportedConstants () {
            this.$options.JOB_STATUS_NEW = JOB_STATUS_NEW;
            this.$options.JOB_STATUS_REJECTED = JOB_STATUS_REJECTED;
            this.$options.JOB_STATUS_EXPIRED = JOB_STATUS_EXPIRED;
            this.$options.JOB_STATUS_READY_TO_EXECUTE = JOB_STATUS_READY_TO_EXECUTE;
            this.$options.JOB_STATUS_IN_PROGRESS = JOB_STATUS_IN_PROGRESS;
            this.$options.JOB_STATUS_PROSKER_CANCEL = JOB_STATUS_PROSKER_CANCEL;
            this.$options.JOB_STATUS_CLIENT_CANCEL = JOB_STATUS_CLIENT_CANCEL;
            this.$options.JOB_STATUS_PROSKER_COMPLETE = JOB_STATUS_PROSKER_COMPLETE;
            this.$options.JOB_STATUS_CLIENT_REJECTED = JOB_STATUS_CLIENT_REJECTED;
            this.$options.JOB_STATUS_COMPLETED = JOB_STATUS_COMPLETED;
            this.$options.JOB_STATUS_PROSKER_HELP_REQUEST = JOB_STATUS_PROSKER_HELP_REQUEST;
            this.$options.JOB_STATUS_CLIENT_HELP_REQUEST = JOB_STATUS_CLIENT_HELP_REQUEST;
        },
        getStatusName (status) {
            if (status === this.$options.JOB_STATUS_NEW) {
                return this.$t('new');
            } else if (status === this.$options.JOB_STATUS_REJECTED) {
                return this.$t('rejected');
            } else if (status === this.$options.JOB_STATUS_READY_TO_EXECUTE) {
                return this.$t('readyToExecute');
            } else if (status === this.$options.JOB_STATUS_IN_PROGRESS) {
                return this.$t('inProgress');
            } else if (status === this.$options.JOB_STATUS_PROSKER_CANCEL) {
                return this.$t('proskerCancel');
            } else if (status === this.$options.JOB_STATUS_CLIENT_CANCEL) {
                return this.$t('clientCancel');
            } else if (status === this.$options.JOB_STATUS_PROSKER_COMPLETE) {
                return this.$t('proskerComplete');
            } else if (status === this.$options.JOB_STATUS_CLIENT_REJECTED) {
                return this.$t('clientRejected');
            } else if (status === this.$options.JOB_STATUS_COMPLETED) {
                return this.$t('complete');
            } else if (status === this.$options.JOB_STATUS_PROSKER_HELP_REQUEST) {
                return this.$t('proskerHelpRequest');
            } else if (status === this.$options.JOB_STATUS_CLIENT_HELP_REQUEST) {
                return this.$t('clientHelpRequest');
            }
        },
        getColorClass (status) {
            if (status === this.$options.JOB_STATUS_NEW) {
                return 'blue lighten-2';
            } else if (status === this.$options.JOB_STATUS_REJECTED) {
                return 'red lighten-1';
            } else if (status === this.$options.JOB_STATUS_READY_TO_EXECUTE) {
                return 'teal lighten-3';
            } else if (status === this.$options.JOB_STATUS_IN_PROGRESS) {
                return 'cyan darken-3';
            } else if (status === this.$options.JOB_STATUS_PROSKER_CANCEL) {
                return 'yellow darken-3';
            } else if (status === this.$options.JOB_STATUS_CLIENT_CANCEL) {
                return 'red darken-2';
            } else if (status === this.$options.JOB_STATUS_PROSKER_COMPLETE) {
                return 'green darken-1';
            } else if (status === this.$options.JOB_STATUS_CLIENT_REJECTED) {
                return 'red darken-4';
            } else if (status === this.$options.JOB_STATUS_COMPLETED) {
                return 'green darken-2';
            } else if (status === this.$options.JOB_STATUS_PROSKER_HELP_REQUEST) {
                return 'yellow darken-2';
            } else if (status === this.$options.JOB_STATUS_CLIENT_HELP_REQUEST) {
                return 'blue darken-2';
            }
        }
    }
};

export default mixin;
