<template>
  <v-col cols="12" class="pt-0 bg-white">
    <v-main class="fill-height pa-0 bg-white">
      <v-row>
        <v-col cols="12" md="3" class="flex-grow-1 flex-shrink-0 border-right py-4">
          <ConversationList :selected-conversation-id="selectedConversationId" :user="currentUser" @open-conversation="openConversation" @new-message="sendNewMessage" :message="currentConversationLastMessage"/>
        </v-col>
        <v-col cols="12" md="9" class="flex-grow-1 flex-shrink-0 py-4">
            <Conversation :message="lastMessage" :conversation="selectedConversation" @new-message="newMessage" v-if="selectedConversation" />
            <div v-else>
              <alert :message="$t('selectConversation')"/>
              <v-row>
                <v-col sm="6">
                  <v-skeleton-loader
                    v-for="index in 5"
                    :key="index"
                    v-bind="skeletonAttrs"
                    type="list-item"
                  ></v-skeleton-loader>
                </v-col>
                <v-col sm="3">
                </v-col>
                <v-col sm="3">
                  <v-skeleton-loader
                    v-for="index in 3"
                    :key="index"
                    v-bind="skeletonAttrs"
                    type="list-item"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </div>
        </v-col>
      </v-row>
    </v-main>
  </v-col>
</template>
<script>
import { mapGetters } from 'vuex';

import ConversationList from '@/views/Apps/Chat/ConversationList.vue';
import Conversation from '@/views/Apps/Chat/Conversation.vue';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import { USER_TYPE_PROSKER, FEATURE_JOBS, MERCADOPAGO_ACCOUNT } from '@/misc/constants';
import { isFeatureEnabled } from '@/misc/featureFlagService';
import Alert from '@/components/socialvue/alerts/Alert.vue';
import userDataMixin from '@/mixins/userDataMixin';

export default {
  components: { Alert, ConversationList, Conversation },
  props: {
    conversationId: {
      type: String
    }
  },
  name: 'Chat',
  mixins: [errorDialog, userDataMixin],
  data () {
    return {
      showCreateJobButton: false,
      showJobs: true,
      selectedConversationId: '',
      missingData: [],
      selectedConversation: null,
      skeletonAttrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
      },
      socket: null,
      currentConversationLastMessage: null,
      lastMessage: null
    };
  },
  watch: {
    getMissingData: function (value) {
      if (value) {
        this.fillMissingData();
      }
    }
  },
  created () {
    this.selectedConversationId = '' || this.conversationId;
    this.fillMissingData();
    this.setImportedConstants();
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),
    getMissingData () {
      return this.$store.getters['auth/missingData'];
    }
  },
  methods: {
    setImportedConstants () {
      this.$options.USER_TYPE_PROSKER = USER_TYPE_PROSKER;
      this.$options.JOBS_ENABLED = isFeatureEnabled(process.env.VUE_APP_RELEASE_VERSION, FEATURE_JOBS);
      this.$options.MERCADOPAGO_ACCOUNT = MERCADOPAGO_ACCOUNT;
    },
    fillMissingData () {
      this.missingData = [];
      this.showJobs = !this.getMissingData.includes(this.$options.MERCADOPAGO_ACCOUNT);
    },
    openConversation (payload) {
      this.selectedConversation = payload.conversation;
    },
    newMessage (message) {
      this.currentConversationLastMessage = message;
    },
    sendNewMessage (message) {
      this.lastMessage = message;
    },
    sendDataLayerInfo () {
      if (window.location.pathname.includes('perfil')) {
      this.trackUserData('load_content', {
      pageType: 'my_account',
      pageSubType: 'messages'
    });
    }
    }
  },
  mounted () {
    this.sendDataLayerInfo();
  }
};
</script>
