<template>
  <v-dialog
    v-model="showAdd"
    width="500"
    persistent
    @keydown.esc="onClose"
    @keydown.enter="saveUserBanner"
  >
    <template v-slot:activator="{ on }">
      <v-btn @click="onNewClicked" class="button white" elevation="0" icon small v-on="on">
         <camera />
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5 bg-prosk-secondary white--text">
        <span class="headline">
          {{ $t("pickBannerImage") }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-file-input
                :label="$t('clickToAddImage')"
                class="image-input"
                accept="image/*"
                @change="handleFileUpload"
                prepend-icon="mdi-camera"
                v-model="banner_img"
                color="#59D79D"
                :error-count="errors.length"
                :error-messages="errors"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <cancel-button :cancel="onClose" :message="$t('cancel')"/>
        <save-button v-if="canSaveImage" :save="saveUserBanner" :loading="loading" :message="$t('save')"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import CancelButton from '@/components/socialvue/buttons/CancelButton.vue';
import verifyImageSize from '@/components/socialvue/mixins/verifyImageSize';
import Camera from '@/icons/svg/camera.svg';

export default {
  name: 'DialogUserBanner',
  components: { Camera, CancelButton, SaveButton },
  props: ['showAdd', 'onClose', 'newItem'],
  mixins: [errorDialog, verifyImageSize],
  data () {
    return {
      banner_img: null,
      loading: false,
      errors: []
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    ...mapActions({
      updateUser: 'auth/updateUser',
      updatePercentage: 'auth/updatePercentage'
    }),
    onNewClicked () {
      this.resetErrors();
      this.resetUserBanner();
      this.newItem();
    },
    resetUserBanner () {
      this.banner_img = null;
    },
    resetErrors () {
      this.errors = [];
    },
    getFormData () {
      const formData = new FormData();
      if (this.banner_img != null) {
        formData.append('banner_img', this.banner_img);
      }
      return formData;
    },
    saveUserBanner () {
      this.resetErrors();
      this.loading = true;
      const formData = this.getFormData();
      const url =
        process.env.VUE_APP_BASE_URL +
        `/api/users/${this.currentUser.id}/medias`;
      if (this.banner_img == null) {
        this.errors.push(this.$t('photoFieldRequired'));
        this.loading = false;
      } else {
        axios
          .post(url, formData)
          .then(() => {
            this.updateUser(this.currentUser.id);
            this.updatePercentage(this.currentUser.id);
            this.resetUserBanner();
            this.loading = false;
            this.onClose();
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error);
          });
      }
    }
  }
};
</script>
<style scoped>
.button {
  height: 40px;
  margin: 20px;
  padding: 9px;
  width: 40px;
}
</style>
