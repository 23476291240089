<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="action(item)"
        :color="color"
        small
        dark
        class="mx-1"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small>
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <span>{{ $t(message) }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'TooltipButton',
  props: ['action', 'icon', 'item', 'color', 'message']
};
</script>
